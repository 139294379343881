<template>
  <v-container class="down-top-padding" fluid>
    <BaseBreadcrumb :breadcrumbs="breadcrumbs" :icon="page.icon" :title="page.title"/>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <ReviewForm
              :errors="errors"
              :review="review"
              @onSubmit="submit"
            />
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено
          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              icon
              @click="snackbarSuccess = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              icon
              @click="snackbarError = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Review} from "@/models/review";
import ReviewForm from "@/components/reviews/ReviewForm";
import BaseBreadcrumb from "@/components/commonComponents/BaseBreadcrumb.vue";
import axiosInstance from "@/axiosInstance";

export default {
  name: 'Edit',
  components: {
    BaseBreadcrumb,
    ReviewForm,
  },
  data: () => ({
    review: {},
    errors: {},
    snackbarSuccess: false,
    snackbarError: false,
    page: {
      title: "Редактирование отзыва"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Отзывы",
        disabled: false,
        to: '/reviews'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/reviews/create'
      },
    ],
  }),
  created() {
    this.find();
  },
  methods: {
    async submit({formData, isNeedRedirect}) {
      try {
        this.errors = {};

        formData.append('_method', 'put');
        await axiosInstance.post('/reviews/' + formData.get('id'), formData);

        await this.find();
        this.snackbarSuccess = true;

        if (isNeedRedirect) {
          await this.$router.push({name: 'reviews-index'});
        }
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async find() {
      const review = await Review.$query().find(this.$route.params.id);
      this.review = review.$attributes;
    },
  },
};
</script>
